// 
// light-mode.scss
// 

// Custom Variable
:root {
    --#{$prefix}logo-lg-height:           20px;
    --#{$prefix}logo-sm-height:           20px;

    --#{$prefix}leftbar-width:            260px;
    --#{$prefix}leftbar-width-md:         160px;
    --#{$prefix}leftbar-width-sm:         70px;
    --#{$prefix}leftbar-condensed-height: 1500px;

    --#{$prefix}topbar-height:             70px;

    --#{$prefix}menu-item-icon-size:       1.1rem;
    --#{$prefix}menu-item-icon-width:      40px;
    --#{$prefix}menu-item-font-size:       0.925rem;
    --#{$prefix}menu-item-padding-x:       8px;
    --#{$prefix}menu-item-padding-y:       9px;

    // Footer Height
    --#{$prefix}footer-height:             60px;

    // Card Border Width
    --#{$prefix}theme-card-border-width:   1px;
}

// Background Light left-sidebar
html[data-menu-color="light"] {
    --#{$prefix}menu-bg:                               linear-gradient( 45deg, hsl(240deg 20% 99%) 0%, hsl(253deg 39% 98%) 24%, hsl(256deg 47% 98%) 35%, hsl(257deg 53% 97%) 45%, hsl(258deg 56% 97%) 55%, hsl(258deg 58% 96%) 65%, hsl(258deg 60% 96%) 76%, hsl(259deg 62% 95%) 100% );
    --#{$prefix}menu-item-color:                       #{$gray-700};
    --#{$prefix}menu-item-hover-color:                 #{$primary};
    --#{$prefix}menu-item-active-color:                #{$primary};
    --#{$prefix}menu-condensed-link-bg:                #313a46;
}

// Dark Mode Left Sidebar
html[data-menu-color="dark"],
    html[data-bs-theme="dark"][data-menu-color="light"] {
    --#{$prefix}menu-bg:                               #313a46;
    --#{$prefix}menu-item-color:                       #8391a2;
    --#{$prefix}menu-item-hover-color:                 #bccee4;
    --#{$prefix}menu-item-active-color:                #ffffff;
    --#{$prefix}menu-condensed-link-bg:                #313a46;
}

// Brand Menu
html[data-menu-color="brand"] {
    --#{$prefix}menu-bg:                                   #{$primary};
    --#{$prefix}menu-item-color:                           #cedce4;
    --#{$prefix}menu-item-hover-color:                     #ffffff;
    --#{$prefix}menu-item-active-color:                    #ffffff;
    --#{$prefix}menu-condensed-link-bg:                #313a46;
}


// Light Topbar
html[data-topbar-color="light"] {
    --#{$prefix}topbar-bg:                         transparent;
    --#{$prefix}topbar-item-color:                 #{$gray-700};
    --#{$prefix}topbar-item-hover-color:           #{$primary};
    --#{$prefix}topbar-search-bg:                  transparent;
    --#{$prefix}topbar-user-bg:                    #{lighten($gray-100, 1%)};
    --#{$prefix}topbar-user-border:                #eef2f7;
}

// Dark Mode Topbar
html[data-bs-theme="dark"][data-topbar-color="light"],
html[data-bs-theme="dark"][data-topbar-color="dark"] {
    --#{$prefix}topbar-bg:                  transparent;
    --#{$prefix}topbar-item-color:          #8391a2;
    --#{$prefix}topbar-item-hover-color:    #bccee4;
    --#{$prefix}topbar-search-bg:           transparent;
    --#{$prefix}topbar-user-bg:             #363f4a;
    --#{$prefix}topbar-user-border:         #414d5d;
}